import React from 'react'

const Nav = () => (
  <div className="side-nav">
    <a
      href="https://www.youtube.com/channel/UCykgzzxJr--809Kd5CvlR0g"
      alt="I Can See the Futura link"
    >
      side project
    </a>
  </div>
)

export default Nav
